import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Container = ({ children }) => {
  const navigate = useNavigate();
  const [onMobile, setOnMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1438) {
        setOnMobile(true);
      } else {
        navigate('/');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [navigate]);

  return onMobile ? children() : null;
};

export default Container
