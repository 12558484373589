import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Loader from './components/Loader'
import PrivateRoute from './containers/PrivateRoute'
import MobileMiddleRoute from './containers/MobileMiddleRoute'

const AuthBase = lazy(() => import('./containers/AuthBase'))
const BankAccountBase = lazy(() => import('./containers/BankAccountBase'))
const CreditCardBase = lazy(() => import('./containers/CreditCardBase'))
const DesignGuideBase = lazy(() => import('./containers/DesignGuideBase'))
const EditProfileBase = lazy(() => import('./containers/EditProfileBase'))
// const HomeBase = lazy(() => import('./containers/HomeBase'))
const MessagesBase = lazy(() => import('./containers/MessagesBase'))
const OnboardingBase = lazy(() => import('./containers/OnboardingBase'))
const PaymentsBase = lazy(() => import('./containers/PaymentsBase'))
const PortfolioBase = lazy(() => import('./containers/PortfolioBase'))
const ProfileBase = lazy(() => import('./containers/ProfileBase'))
const ProjectBase = lazy(() => import('./containers/ProjectBase'))
const ProjectsBoardBase = lazy(() => import('./containers/ProjectsBoardBase'))
const ServiceBase = lazy(() => import('./containers/ServiceBase'))
const ServicePublicBase = lazy(() => import('./containers/ServicePublicBase'))
const StaticPageBase = lazy(() => import('./containers/StaticPageBase'))
const ArtistsBoardBase = lazy(() => import('./containers/ArtistsBoardBase'))
const ServicesBoardBase = lazy(() => import('./containers/ServicesBoardBase'))
const GeneralSearchBase = lazy(() => import('./containers/GeneralSearchBoardBase'))
const HowItWorksBase = lazy(() => import('./containers/HowItWorksBase'))
const PortfolioPublicBase = lazy(() => import('./containers/PortfolioPublicBase'))
const MobileSearchBase = lazy(() => import('./containers/MobileSearchBase'))
const ProjectPublicBase = lazy(() => import('./containers/ProjectPublicBase'))
const PaymentsMobileRedirectBase = lazy(() => import('./containers/PaymentsBase/MobileRedirect'))
const DirectHireBase = lazy(() => import('./containers/DirectHireBase'))
const RequestQoutationBase = lazy(() => import('./containers/DirectHireBase/RequestQoutationForm'))
const HomePageV2 = lazy(() => import('./containers/HomeBaseV2'))
const RefferralBase = lazy(() => import('./containers/ReferralBase'))

// NFT Containers
const NFTHome = lazy(() => import('./nft/NFTHomeBaseComponent'))

/**
  * Render function that wraps standard routes
  * with suspense
  */
const wrapSuspense = (Component) => (
  <Suspense fallback={<Loader />}>
    <Component />
  </Suspense>
)

export default () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={wrapSuspense(HomePageV2)} />
      <Route path='/auth/*' element={wrapSuspense(AuthBase)} />
      <Route path='/design' element={wrapSuspense(DesignGuideBase)} />
      <Route path='/how-it-works' element={wrapSuspense(HowItWorksBase)} />
      <Route path='/portfolio-details/:userCode?/:portfolioIndex?' element={wrapSuspense(PortfolioPublicBase)} />
      <Route path='/service-details/:serviceId' element={wrapSuspense(ServicePublicBase)} />
      <Route path='/project-details/:id' element={wrapSuspense(ProjectPublicBase)} />
      <Route
          path="/pages/:slug"
          element={
            <Suspense fallback={<Loader />}>
              <StaticPageBase />
            </Suspense>
          }
        />
      <Route path='/all' element={wrapSuspense(GeneralSearchBase)} />

      {/* public NFT PAGES */}
      <Route path='/nft' element={wrapSuspense(NFTHome)} />
      {/* Private Routes here */}
      <Route path='/onboarding/*' element={<PrivateRoute>{wrapSuspense(OnboardingBase)}</PrivateRoute>} />
      <Route path='/profile/:userId/*' element={<PrivateRoute>{wrapSuspense(ProfileBase)}</PrivateRoute>} />
      <Route path='/edit-profile/*' element={<PrivateRoute>{wrapSuspense(EditProfileBase)}</PrivateRoute>} />
      <Route path='/project' element={<PrivateRoute>{wrapSuspense(ProjectBase)}</PrivateRoute>} />
      <Route path='/projects' element={<PrivateRoute>{wrapSuspense(ProjectsBoardBase)}</PrivateRoute>} />
      <Route path='/messages/:token?' element={<PrivateRoute>{wrapSuspense(MessagesBase)}</PrivateRoute>} />
      <Route path='/credit-card' element={<PrivateRoute>{wrapSuspense(CreditCardBase)}</PrivateRoute>} />
      <Route path='/bank-account/:bankAccountIndex?' element={<PrivateRoute>{wrapSuspense(BankAccountBase)}</PrivateRoute>} />
      <Route path='/service/:serviceId?' element={<PrivateRoute>{wrapSuspense(ServiceBase)}</PrivateRoute>} />
      <Route path='/portfolio/:portfolioIndex?' element={<PrivateRoute>{wrapSuspense(PortfolioBase)}</PrivateRoute>} />
      <Route path='/payments/*' element={<PrivateRoute>{wrapSuspense(PaymentsBase)}</PrivateRoute>} />
      <Route path='/artists' element={<PrivateRoute>{wrapSuspense(ArtistsBoardBase)}</PrivateRoute>} />
      <Route path='/services' element={<PrivateRoute>{wrapSuspense(ServicesBoardBase)}</PrivateRoute>} />
      <Route path='/direct-hire/:id' element={<PrivateRoute>{wrapSuspense(DirectHireBase)}</PrivateRoute>} />
      <Route path='/request-qoutation/:id/*' element={<PrivateRoute>{wrapSuspense(RequestQoutationBase)}</PrivateRoute>} />
      <Route path='/referral' element={<PrivateRoute>{wrapSuspense(RefferralBase)}</PrivateRoute>} />

      {/* route only available when on mobile & tablets */}
      <Route path='/mobile-search' element={<MobileMiddleRoute>{wrapSuspense(MobileSearchBase)}</MobileMiddleRoute>} />
      <Route path='/payments-mobile/redirect' element={<MobileMiddleRoute>{wrapSuspense(PaymentsMobileRedirectBase)}</MobileMiddleRoute>} />
    </Routes>
  </BrowserRouter>
)
