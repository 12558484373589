// module imports
import {
  CalendarOneWeekIcon,
  AlarmClockIcon,
  CalendarTwoWeeksIcon,
  CalendarMonthIcon,
  CalendarUnknownIcon
} from '@/components/Icons'
import React from 'react'

// Global urls
export const API_URL = process.env.REACT_APP_API_URL
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL
export const SAMPLE_API_URL = process.env.REACT_APP_SAMPLE_API_URL
export const CLOUD_FRONT_URL = process.env.REACT_APP_CLOUD_FRONT_URL
export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL
export const ADMIN_CLOUD_FRONT_URL = process.env.REACT_APP_ADMIN_CLOUD_FRONT_URL
export const ADMIN_SOCKET_IO_PATH = process.env.REACT_APP_ADMIN_SOCKET_IO_PATH
export const SOCIAL_SHARE_BASE_URL = process.env.REACT_APP_SOCIAL_SHARE_BASE_URL
export const HOST_NAME = process.env.REACT_APP_HOST_NAME
// misc
export const SIZE_PER_PAGE = 10
export const CURRENCY = 'PHP'
export const SESSION_TIMEOUT = 55 * 60 * 1000
export const COVER_IMAGE_FALLBACK = 'static/assets/images/bg/artphorce-fallback-cover.jpg'
export const PROFILE_IMAGE_FALLBACK = 'static/assets/images/bg/artphorce-fallback-profile.jpg'
export const DIRECT_HIRE_QOUTATION = 'request-a-qoutation'
export const DIRECT_HIRE_SERVICES = 'existing-services'
// CARD STATUSES
export const CARD_VERIFIED = 'VERIFIED'
export const CARD_IN_REVIEW = 'IN_REVIEW'
export const CARD_FAILED = 'FAILED'
// phone regexp
export const PH_PHONE_REGEX = /^(09|9|\+639)\d{9}$/
export const INTERNATIONAL_PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const interests = [
  'Advertising',
  'Brand Identity',
  'Business',
  'Illustration',
  'Image Editing',
  'Merchandise',
  'Packaging',
  'Web & App Design'
]
export const jobTypes = [
  {
    code: 'advertising',
    value: 'Advertising',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/advertising.png`
  },
  {
    code: 'business',
    value: 'Business',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/business.png`
  },
  {
    code: 'brand_identity',
    value: 'Brand Identity',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/business.png`
  },
  {
    code: 'illustration',
    value: 'Illustration',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/illustration.png`
  },
  {
    code: 'image_editing',
    value: 'Image Editing',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/image_editing.png`
  },
  {
    code: 'merchandise',
    value: 'Merchandise',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/merchandise.png`
  },
  {
    code: 'packaging',
    value: 'Packaging',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/packaging.png`
  },
  {
    code: 'app_design',
    value: 'Web & App Design',
    image: `${CLOUD_FRONT_URL}static/assets/images/icons/homepage/web_app.png`
  }
]
export const privacy = [
  { code: 'public', value: 'Public' },
  { code: 'private', value: 'Private' }
]
export const tiers = {
  basic: 'Basic',
  standard: 'Standard',
  premium: 'Premium',
  custom: 'Custom'
}
export const defaultOffers = {
  vector: 'Initial Concept',
  src_file: 'Source Files',
  // revisions: 'Revisions',
  high_res: 'Hi-Resolution Files ',
  guide_book: 'Guide Book'
}
export const milestonePercentages = { 1: 25, 2: 50, 3: 100 }
export const withdrawalStatuses = { '-1': 'Rejected', 1: 'Pending', 2: 'Approved' }
export const defaultPageSize = 1000
export const validImageTypes = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']
export const uploadAccepts = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPEG', 'image/JPG', 'image/PNG']
export const modalProps = { width: 778, footer: null, closable: false, bodyStyle: { padding: 0 } }
export const projectPriceRange = [
  { code: 1, label: 'P2,500 - P5,000', min_budget: 2000, max_budget: 5000 },
  { code: 2, label: 'P5,001 - P10,000', min_budget: 5001, max_budget: 10000 },
  { code: 3, label: 'P10,001 - P20,000', min_budget: 10001, max_budget: 20000 },
  { code: 4, label: 'P20,001 - P30,000', min_budget: 20001, max_budget: 30000 },
  { code: 5, label: 'P30,001 - P50,000', min_budget: 30001, max_budget: 50000 },
  { code: 6, label: 'More than P50,000', min_budget: 50000, max_budget: 50000 }
]
export const filterSuggestions = [
  { filter_name: 'Projects', link: '/projects' },
  { filter_name: 'Portfolios/Sample Works', link: '/artists' },
  { filter_name: 'Artists', link: '/artists' },
  { filter_name: 'Services', link: '/services' }
]
export const timelines = [
  {
    code: '48-hours',
    title: '48 hours',
    description: 'Rush job! Quick turnaround, additional fees may apply',
    icon: <AlarmClockIcon />
  },
  {
    code: '1-week',
    title: '1 week',
    description: '',
    icon: <CalendarOneWeekIcon />
  },
  {
    code: '2-weeks',
    title: '2 weeks',
    description: '',
    icon: <CalendarTwoWeeksIcon />
  },
  {
    code: '1-month',
    title: '1 month',
    description: '',
    icon: <CalendarMonthIcon />
  },
  {
    code: 'choose-date',
    title: 'Choose start date and end date',
    description: '',
    icon: <CalendarUnknownIcon />
  },
  {
    code: 'not-sure',
    title: 'Not sure',
    description: "I'd like to chat with my designer",
    icon: <AlarmClockIcon />
  }
]
export const CUSTOM_BUDGET = 6
export const CHOOSE_DATE = 'choose-date'
export const categories = [
  {
    title: 'Portfolio',
    slug: 'portfolio'
  },
  {
    title: 'Projects',
    slug: 'projects'
  },
  {
    title: 'Artists',
    slug: 'artists'
  },
  {
    title: 'Services',
    slug: 'services'
  }
]

export const pathsWithTransparentNav = [
  'profile',
  'nft'
]

/** Breakpoints follow antDesign mobile-first
 * sm : 576px
 * md: 768px
 * lg screens: 992px
 * xl: 1200px
 * xxl: 1600px
 */
