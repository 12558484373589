import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import * as Sentry from "@sentry/remix";
// import { Integrations } from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'
// import { ConfigProvider } from 'antd';
import ErrorBoundary from './ErrorBoundary'

// Sentry.init({
//   dsn: 'https://4786130e2f034af7ab5f165faff1176a@o587893.ingest.sentry.io/5738818',
//   integrations: [new Sentry.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

window.onload = function () {
  if (window.Xendit && process.env.REACT_APP_XENDIT_PK) {
    window.Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_PK)
  } else {
    console.error('Xendit or Publishable Key is not available')
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    {/* <ConfigProvider theme={customTheme}> */}
     <App />
     {/* </ConfigProvider> */}
   </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
