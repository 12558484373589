import React from 'react'
import { appendCloudFront } from '@/utils/helpers'
import SendMessage from '@/assets/images/messages/uil_arrow-circle-up.png'
import AttachImage from '@/assets/images/messages/uil_image-v.png'
import AttachFile from '@/assets/images/messages/uil_file-alt.png'
// svg imports
import {ReactComponent as Download} from '@/assets/svg/download-icon.svg'
import {ReactComponent as Info} from '@/assets/svg/uil_info-circled.svg'
import {ReactComponent as Attachments} from '@/assets/svg/uil_image-v.svg'
import {ReactComponent as Documents} from '@/assets/svg/uil_file-alt.svg'
import {ReactComponent as CloseCircle} from '@/assets/svg/uil_close-circled.svg'
import {ReactComponent as ArrowLeft} from '@/assets/svg/uil_arrow-left.svg'
import {ReactComponent as Search} from '@/assets/svg/uil_search.svg'
import {ReactComponent as ImagePlaceholder} from '@/assets/svg/uil_orange-img-placeholder.svg'
import {ReactComponent as EmbedPlaceholder} from '@/assets/svg/uil_orange-embed-placeholder.svg'
import {ReactComponent as Delete} from '@/assets/svg/uil_delete.svg'
import {ReactComponent as CircleCheck} from '@/assets/svg/uil_circle_check.svg'
import {ReactComponent as MessageWhite} from '@/assets/svg/uil_message-white.svg'
import {ReactComponent as BriefcaseWhite} from '@/assets/svg/uil_briefcase-white.svg'
import {ReactComponent as WhiteSearch} from '@/assets/svg/uil_search-white.svg'
import {ReactComponent as WhiteHamburger} from '@/assets/svg/uil_hamburger-white.svg'
import {ReactComponent as UploadGrey} from '@/assets/svg/upload.svg'
import {ReactComponent as CircleCheckBlack} from '@/assets/svg/uil_circle_check-black.svg'
// direct hire timeline svgs
import {ReactComponent as AlarmClock} from '@/assets/svg/timeline-icons/clarity_alarm-clock-solid.svg'
import {ReactComponent as CalendarOne} from '@/assets/svg/timeline-icons/clarity_calendar-solid.svg'
import {ReactComponent as CalendarTwo} from '@/assets/svg/timeline-icons/clarity_calendar-2.svg'
import {ReactComponent as CalendarMonth} from '@/assets/svg/timeline-icons/clarity_calendar-month.svg'
import {ReactComponent as CalendarUnknown} from '@/assets/svg/timeline-icons/clarity_calendar-unknown.svg'
import {ReactComponent as OrangeLock} from '@/assets/svg/uil_orange-lock.svg'

// export const ArrowLeftIcon = () => <img src={appendCloudFront('static/assets/images/icons/ArrowLeft.svg')} alt='arrow-left-icon' className='arrow-left' />
export const ArrowLeftIcon = () => <ArrowLeft className='arrow-left' />
export const UilBellIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-bell.svg')} alt='bell-icon' />
export const UilBriefcaseIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-briefcase.svg')} alt='brief-case-icon' />
export const UilEnvelopeIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-envelope-alt.svg')} alt='envelope-icon' />
export const UilArrowLeftIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-arrow-left.svg')} alt='arrow-left-icon' />
export const UilWhiteMessageIcon = props => <MessageWhite alt='' {...props} />
export const UilWhiteBriefcaseIcon = props => <BriefcaseWhite alt='' {...props} />
export const BankBriefCaseIcon = () => <img src={appendCloudFront('static/assets/images/icons/bank.svg')} alt='bank-brief-case' />
export const QuestionCircleIcon = () => <img src={appendCloudFront('static/assets/images/icons/uil-question-circle.svg')} alt='question-cirle' />
export const OrangeDownload = () => <img src={appendCloudFront('static/assets/images/icons/download.svg')} alt='orang-download' />
export const MobileStep1Icon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-step-icon.svg')} alt='' />
export const MobileStep2Icon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-step2-icon.svg')} alt='' />
export const MobileStep3Icon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-step3-icon.svg')} alt='' />
export const PdfIcon = ({ width = 'auto', height = 'auto' }) => <img style={{ cursor: 'pointer' }} src={appendCloudFront('static/assets/images/pdf-file.svg')} alt='' width={width} height={height} />
export const UploadIcon = props => <UploadGrey alt='' {...props} />
export const CheckBlackIcon = props => <CircleCheckBlack {...props} />

// Icons for mobile
export const HamburgerIcon = () => <img src={appendCloudFront('static/assets/images/icons/hamburger-icon.svg')} alt='' />
export const MobileCloseIcon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-close-icon.svg')} alt='' />
export const MobileSearchIcon = () => <img src={appendCloudFront('static/assets/images/icons/mobile-search-icon.svg')} alt='' />
export const WhiteSearchIcon = props => <img src={WhiteSearch} alt='' {...props} />
export const WhiteHamburgerIcon = props => <img src={WhiteHamburger} alt='' {...props} />

// revamped messages Icons
export const SendMessageIcon = () => <img src={SendMessage} alt='' />
export const AttachImageIcon = () => <img src={AttachImage} alt='' />
export const AttachFileIcon = () => <img src={AttachFile} alt='' />

export const AttachmentsIcon = () => <Attachments alt='' />
export const DocumentsIcon = () => <Documents alt='' />
export const DownloadIcon = () => <Download alt='' />
export const InfoIcon = () => <Info alt='' />
export const CloseIcon = () => <CloseCircle alt='' />
export const SearchIcon = () => <Search alt='' />
export const DeleteIcon = () => <Delete alt='' />
export const CircleCheckIcon = () => <CircleCheck alt='' />

// orange Icons
export const ImgOrangePlaceholder = (props) => <ImagePlaceholder {...props} />
export const EmbedOrangePlaceholder = (props) => <EmbedPlaceholder {...props} />
export const OrangeLockIcon = (props) => <OrangeLock {...props} />

// timeline icons
export const AlarmClockIcon = props => <AlarmClock {...props} />
export const CalendarOneWeekIcon = props => <CalendarOne {...props} />
export const CalendarTwoWeeksIcon = props => <CalendarTwo {...props} />
export const CalendarMonthIcon = props => <CalendarMonth {...props} />
export const CalendarUnknownIcon = props => <CalendarUnknown {...props} />
