import React from 'react'
import Routes from './routes'
import Interceptors from './utils/interceptor'
import store from './store'
import { StoreProvider } from 'easy-peasy'
import './assets/styles/app.less'
import { ConfigProvider } from 'antd'
import Xendit from 'xendit-node'

Interceptors.setupInterceptors(store)
// const xenditClient = new Xendit({
//   secretKey: process.env.REACT_APP_XENDIT_PK,
// })
// Xendit.setPublishableKey(process.env.REACT_APP_XENDIT_PK)

const customTheme = {
  token: {
    colorPrimary: '#f06922'
    // Define other tokens as needed
  }
}

const App = () => (
  <ConfigProvider theme={customTheme}>
  <StoreProvider store={store}>
    <Routes />
  </StoreProvider>
  </ConfigProvider>
)

export default App
