import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

const Container = ({ children }) => {
  const isAuthenticated = localStorage.getItem('auth')

  if (!isAuthenticated || typeof isAuthenticated === 'undefined') {
    return <Navigate to="/auth" replace />
  }

  return typeof children === 'function' ? children({}) : children
}

export default Container
